import styled from "styled-components"
import SearchBox from "./search-box"

export default styled(SearchBox)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 0;
  border-bottom: 1px solid ${({ theme }) => theme.background};
  background: ${({ theme }) => theme.foreground};
  cursor: text;

  .SearchInput {
    outline: none;
    border: none;
    padding: .5em;
    font-size: .85em;
    transition: 200ms;
    color: ${({ theme }) => theme.background};
    ::placeholder {
      color: ${({ theme }) => theme.faded};
    }
  }

  .SearchIcon {
    width: 1em;
    margin: 0.3em;
    color: ${({ theme }) => theme.background};
    pointer-events: none;
  }
`