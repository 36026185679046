import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  InfiniteHits,
  Index,
} from "react-instantsearch-dom"



const HitCount = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits

  return hitCount > 0 ? (
    <div className="HitCount">
      {hitCount} result{hitCount !== 1 ? `s` : ``}
    </div>
  ) : null
})

const PostHit = ({ hit }) => (
    
      <p>

        <Highlight attribute="title" hit={hit} tagName="mark" />
      
        {hit.links &&
          hit.links.map(link => (
          <a target="_blank" rel="noreferrer" title={link.title} href={link.url} data-tip={link.url} data-background-color="#000000" data-offset="{'top': 10, 'right': 10}">[{link.title || "+"}]</a>
          ))
        }
      </p>

)

const HitsInIndex = ({ index }) => (
  <Index indexName={index.name}>
    <HitCount />
    <InfiniteHits className="Hits" hitComponent={PostHit} />
  </Index>

)

const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map(index => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)

export default SearchResult