import styled, { css } from "styled-components"
import SearchResult from "./search-result"

const Popover = css`
  max-height: 80vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 100%;
  margin-top: 1em;
  width: 100%;
  max-width: 45em;
  padding: 1em;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.foreground};
`

export default styled(SearchResult)`
  display: ${props => (props.show ? `block` : `none`)};
  ${Popover}

  .HitCount {
    font-size: .85em;
    display: flex;
    justify-content: flex-end;
  }

  .Hits {

    ul {
      width: 100%;
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }

    li .ais-Snippet {
      display: inline-block;
      width: 100%;
      font-size: .85em;
    }

    .ais-Highlight-highlighted, .ais-Snippet-highlighted  {
      background: transparent;
      color: ${({ theme }) => theme.foreground};
      border-bottom: 1px solid ${({ theme }) => theme.foreground};
    }

    li.ais-InfiniteHits-item {
      margin-bottom: 0em;

      a {
        text-decoration: none;
        transition: background-color .2s ease-in-out, color .2s ease-in-out;
        padding: .05em .25em; 
        color: ${({ theme }) => theme.foreground};
        background: ${({ theme }) => theme.background};
        margin-left: 0.25em;

      }

      a:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.background};
        background: ${({ theme }) => theme.foreground};
        padding: .05em .25em; 
      }

    }

    .ais-InfiniteHits-loadMore {
      border-radius: 0px;
      margin-bottom: 1em;
      border: none;
      cursor: pointer;
      padding: .25rem calc(.5rem - 1px);
    }

    .ais-InfiniteHits-loadMore--disabled {
      opacity: 0;
      visibility: hidden;
      display: none;
    }

  }

`