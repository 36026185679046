import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents,
  filterOutCatsWithParents
} from '../lib/helpers'
import ResourceArchiveList from '../components/resource-archive-list'
//import StudentWorkPreviewList from '../components/student-work-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import '../styles/pagination.css'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
          metadata {
            dimensions {
              aspectRatio
            }
            lqip
          }
          _id
        }
  }
  query ResourceQuery($slug: String!) {
    
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }

    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
            slug {
              current
            }
          }
        }
      }
    }
    
    resources: allSanityResource(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
    edges {
      node {
        id
        title
        author
        source
        links {
          title
          url
        }
        year
        categories {
          slug {
            current
          }
          parents {
            slug {
              current
            }
          }
        }
      }
    }
  }

    cat:sanityCategory(slug: {current: {eq: $slug}}) {
      title
    }

  }
`

const ResourceCat = props => {
  const {data, errors} = props

  //console.log('omg')

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const cat = (data || {}).cat

  //

/*
  const resourceEdges = (data.resources || {}).edges || []

  let resourceNodesFiltered = resourceEdges
  .filter(edge => edge.node.categories.some(c => c.parents.some(p => p.slug.current.includes(props.pageContext.slug)))) 
*/

  const resourceNodes = (data || {}).resources
    ? mapEdgesToNodes(data.resources)
      .filter(resource => resource.categories.some(c => c.parents.some(p => p.slug.current.includes(props.pageContext.slug)))) 
    : []

  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const childCatNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithParents)
      .filter(cat => cat.parents.some(p => p.slug.current.includes(props.pageContext.slug)))
    : []

    //console.log(childCatNodes)

  const linkcat = '/resources/'+props.pageContext.slug+'/'
  const title = 'Resources → '+cat.title

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      <SEO
        title={site.title}
        author={site.author}
        description={site.description}
        keywords={site.keywords}
      />
      <Container>

        {resourceNodes && (
          <ResourceArchiveList
            title={title}
            nodes={resourceNodes}
            childCatNodes={childCatNodes}
            browseMoreHref={linkcat}
            slug={props.pageContext.slug}
          />
        )}

      </Container>
    </Layout>
  )
}

export default ResourceCat