import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

import {BsSearch} from 'react-icons/bs';

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={className}>
      <input
        className="SearchInput"
        type="text"
        placeholder="Search Resources..."
        aria-label="Search Resources"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
      <BsSearch className="SearchIcon" />
    </form>
  )
)