import React from 'react'
import ResourceArchive from './resource-archive'
import ReactTooltip from 'react-tooltip';

import '../styles/resource-archive-list.css'

import Search from "../components/resources-search"
const searchIndices = [{ name: `Resources`, title: `Resources` }]



function ResourceArchiveList (props) {

  //console.log('ed')
  //console.log(props.slug)
  //console.log(props.nodes)

  return (
    <div className="resource-archive-list wrapper">
      {props.title && props.browseMoreHref && <h3>{props.title}</h3>}
      <div className="resources-search"><Search indices={searchIndices} /></div>

      {props.childCatNodes &&
        props.childCatNodes.map(node => (
          
          <div className="child-cat">

            {node.title &&
              <h4>{node.title}</h4>
            }
            {props.nodes.filter(n => n.categories.some(c => c.slug.current.includes(node.slug.current))).map(filteredNode => (
              <ResourceArchive {...filteredNode} />
            ))}
          <ReactTooltip />
          </div>

      ))}

        {/*props.nodes &&
          props.nodes.map(node => (
              <ResourceArchive {...node} />
          ))*/}

    </div>
  )
}

ResourceArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default ResourceArchiveList