import React from 'react'

import '../styles/resource-archive.css'

function ResourcePostArchive (props) {

  //console.log(props);

  let book = false;

  if(props.author && props.author !== null && props.year && props.year != null && props.source && props.source != null){
    book = true;
  }


  return (

    <>
      <p>
      {book &&
        <>{props.author}. {props.year}, <em>{props.title}</em>, {props.source}. </>
      }

      {!book &&
        <>{props.title} </>
      }
      
        {props.links &&
          props.links.map(link => (
          <a target="_blank" rel="noreferrer" title={link.title} href={link.url} data-tip={link.url} data-background-color="#000000" data-offset="{'top': 10, 'right': 10}">[{link.title || "+"}]</a>
          ))
        }
      </p>
    </>
  )
}

export default ResourcePostArchive